import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {path:'/', redirect: '/home'},
	{
		path: '/home',
		name: 'Home',
		component: () => import('../views/home/homeIndex.vue'),
		meta: {
			title: '',
		},
	},
	// 隐私协议
	{
		path: '/privacy-policy',
		name: 'Agreement',
		component: () => import('../views/agreement/agreementIndex.vue'),
		meta: {
			title: '',
		},
	},
	// 用户注册合同
	{
		path: '/user-policy',
		name: 'User',
		component: () => import('../views/userPolicy/userPolicy.vue'),
		meta: {
			title: '',
		},
	},
	// 贷款合同
	{
		path: '/loan-policy',
		name: 'Loan',
		component: () => import('../views/loanPolicy/loanPolicy.vue'),
		meta: {
			title: '',
		},
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
