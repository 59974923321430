<template>
  <div class="app-container">
   <router-view v-slot="{ Component }">
			<component :is="Component" :key="$route.name" />
		</router-view>
  </div>
</template>
<script setup>

</script>

<style lang="scss" scoped>
.app-container {
  max-width: 1920px;
  margin: 0 auto;
}
</style>
